<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Edit Category"
      ok-title="Edit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label=""
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="toEdit.tag"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Ajouter tags</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <v-select
              v-model="newTag.categorie"
              id="categoryList"
              placeholder="Category"
              :options="categories"
              @input="displaySubCategorie(newTag)"
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="
                showcat === true && !this.newTag.categorie
              "
            >
              <strong>categorie est obligatoire </strong>
            </p>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <v-select
              v-model="newTag.subCategorie"
              id="categoryList"
              placeholder="Category"
              :options="subCategories"
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="showsub === true && !this.newTag.subCategorie"
            >
              <strong>sous-categorie est obligatoire </strong>
            </p>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-input
              v-model="newTag.label"
              placeholder="Label"
              type="text"
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="showsub === true && this.subcategory.label === ''"
            >
              <strong>tags est obligatoire </strong>
            </p>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-button variant="primary" block @click="_addCategory()">
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card-actions action-collapse title="Liste des tags">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block mr-1"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-tags"
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Label'" class="text-nowrap">
            <span>
              {{ props.row.label }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'categories'"
            class="text-nowrap"
          >
            <span>
              <div v-for="cat in props.row.categories" :key="cat">
                <div v-for="cat1 in categories" :key="cat1._id">
                  <div v-if="cat === cat1._id">
                    <feather-icon :icon="cat1.icon" /> {{ cat1.label }}
                  </div>
                </div>
              </div>
            </span>
          </span>
          <span v-else-if="props.column.label === 'Action'" class="text-nowrap">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  id="toggle-btn"
                  v-b-modal.modal-prevent-closing
                  @click="_editCategory(props.row)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="_deleteCategory(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {

  components: {
    vSelect,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BAlert,
    BModal,
    VBModal,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
    BCardActions,
  },

  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      showcat: false,
      showsub: false,

      name: "",
      nameState: null,
      label: "",
      toEdit: {},
      subcategory: {
        label: "",
        categories: [],
      },
      // fixed
      subCategories:[],
      newTag:{},
      tagsArray:[],
      columns: [
        {
          label: "Tags",
          field: "tag",
        },
        {
          label: "Sous catégorie",
          field: "subCategory",
        },
        {
          label: "catégorie",
          field: "category",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
    };
  },

  methods: {
    advanceSearch(val) {
      this.searchTerm = val;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      axios.put(
        "http://102.219.178.151/backend/api/categories/"
        +this.toEdit.idCategory+"/sub-categories/"+this.toEdit.idSubCategory+"/tags/"+this.toEdit.idTag,{"label":this.toEdit.tag}
      ).then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Tag bien modifier`,
              icon: "UserIcon",
              variant: "success",
            },
          });
          this.getAllTags()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Erreur lors de la modificatio,`,
              icon: "UserIcon",
              variant: "danger",
            },
          });
          this.errorMessage = error.message;
          console.log(this.errorMessage);
        });
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    displaySubCategorie(newTag){
      this.subCategories=this.newTag.categorie.subCategories
    },
    _addCategory() {
      if((this.newTag.categorie === "")|| (this.newTag.categorie == undefined)){
        this.showsub = true;
      }
      if((this.newTag.subCategorie === "")|| (this.newTag.subCategorie == undefined)){
        this.showcat = true;
      }
      if (this.newTag.subCategorie && this.newTag.categorie) {
        axios.post(
        "http://102.219.178.151/backend/api/categories/"
        +this.newTag.categorie._id+"/sub-categories/"+this.newTag.subCategorie._id+"/tags",{"label":this.newTag.label}
      ).then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tags bien ajouté",
                icon: "PlusIcon",
                variant: "success",
              },
            });
            this.getAllTags()
         })
        .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Erreur lors de l'ajout",
                icon: "PlusIcon",
                variant: "danger",
              },
            });
          })
          this.newTag = {};
        };
    },
    _editCategory(tags) {
      this.toEdit = tags
    },
    _deleteCategory(tags) {
      axios.delete(
        "http://102.219.178.151/backend/api/categories/"
        +tags.idCategory+"/sub-categories/"+tags.idSubCategory+"/tags/"+tags.idTag
      ).then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Tag bien supprimer`,
              icon: "UserIcon",
              variant: "success",
            },
          });
          this.getAllTags()
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Erreur lors de la suppression,`,
              icon: "UserIcon",
              variant: "danger",
            },
          });
          this.errorMessage = error.message;
          console.log(this.errorMessage);
        });
    },
    // added
    async getAllTags(){
      this.tagsArray=[]
      var newArrayToDisplay=[]
      if(!this.categories){
      const response = await axios.get(
        "http://102.219.178.151/backend/api/categories"
      );
      newArrayToDisplay=response.data.data
      }else{
        newArrayToDisplay=this.categories
      }
        newArrayToDisplay.forEach(element => {
        element.subCategories.forEach(subElement => {
            subElement.tags.forEach(tag => {
                this.tagsArray.push(
                  {
                    "idCategory":element._id,
                    "idSubCategory":subElement._id,
                    "idTag":tag._id,
                    "category":element.label,
                    "subCategory":subElement.label,
                    "tag":tag.label
                  }
                )
            });
        });
      });
      this.rows = this.tagsArray;
    }
  },
  mounted() {
    this.getAllTags()
    console.log("categories",this.categories)
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>